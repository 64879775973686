<template>
  <!-- If don't saved -->
  <ModalEmpty
    v-if="isNoSaved"
    @close="
      () => {
        isNoSaved = false
        leaveRoute = ''
      }
    "
  >
    <div class="w-500 p-30 flex ai-c jc-c fd-c ta-center">
      <h1 class="title">Не сохранили изменения</h1>
      <div class="t-grey-dark fs-16 mt-5 mb-30">
        Ваши изменения будут утерянны если их не сохранить, все равно хотите уйти?
      </div>

      <div class="grid ggap-10 w-100">
        <div class="grid gtc-2 ai-c ggap-10">
          <button class="btn transparent-grey jc-c" @click="$router.push(leaveRoute)">
            <BaseIcon class="ic-16 red" icon="close-circle" />
            Не сохранять и уйти
          </button>
          <button class="btn primary jc-c" :disabled="!isNoData" @click="submitAction">
            <BaseIcon class="ic-16 white" icon="check-circle" />
            Отправить
          </button>
        </div>
      </div>
    </div>
  </ModalEmpty>

  <ModalWrapper @close="closeAction">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-10">
        <BaseIcon class="ic-24 primary" icon="plus-circle" />
        <h1 class="title">Новая заявка</h1>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <!-- <div class="form-add grid gtc-1-auto"> -->
      <form class="form-add pb-40 pos-r z0" id="form-add">
        <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg white" />
        <section class="form-add__section grid ggap-20">
          <div class="flex ai-c ggap-10">
            <h2 class="title">Основные данные</h2>
          </div>

          <!-- type && project -->
          <div class="grid gtc-2 ggap-20 ai-fs">
            <FormAutocomplete
              label="Тип заявки"
              required
              :options="optionsAllTypes"
              :isSearch="false"
              v-model="formData.type"
            />
            <FormAutocomplete
              label="Проект"
              :helper="`<p><b>Проект это</b> - не официальное наименование дочерней компании Холдинга.</p><p>Укажите к какому проекту относится данный документ.</p>`"
              required
              :options="optionsProjects"
              v-model="formData.company"
            />
          </div>

          <!-- for_who -->
          <div class="grid gtc-2 ggap-20 ai-fs">
            <FormAutocomplete
              label="Для кого"
              required
              :options="forWho"
              :isSearch="false"
              v-model="formData.for_user"
            />
            <FormDate label="Примерная дата исполнения" required v-model="formData.release_date" />
          </div>
          <BaseAlert type="yellow" v-if="formData.type?.value === 'laptop'">
            Заявки "день-в-день" не приветствуются, пожалуйста планируйте подачу заявок заранее, минимум за 3 рабочих
            дня. В случае необходимости закупа, срок исполнения заявки может быть увеличен.
          </BaseAlert>
          <div
            class="grid gtc-1 ggap-20 ai-fs"
            v-if="['for_new_employee', 'for_employee'].includes(formData?.for_user?.value)"
          >
            <FormAutocompleteSearch
              class="f-1"
              v-if="formData?.for_user?.value === 'for_employee'"
              label="Сотрудник"
              placeholder="Выберите сотрудника"
              required
              :options="optionsUsers"
              :isLoad="isUserLoad"
              :isMultiple="false"
              @search="searchUsers($event)"
              v-model="formData.user"
            >
              <template #tag="{ item }">
                <ItemUser :item="item" @remove="removeUserItem" />
              </template>
            </FormAutocompleteSearch>
            <FormInput
              v-if="formData?.for_user?.value === 'for_new_employee'"
              class="sm w-100"
              label="ФИО сотрудника"
              placeholder="-"
              v-model="formData.new_employee"
              @keydown.enter.prevent
              required
            />
          </div>
        </section>

        <section class="form-add__section grid ggap-20" v-if="formData?.type?.value">
          <div class="flex ai-c">
            <h2 class="title">{{ formData?.type?.text }}</h2>
          </div>
          <div class="grid gtc-1 ggap-20 ai-fs" v-for="item in typeFields" :key="item.id">
            <FormSelect
              :label="item.text"
              v-if="item.type === 'select' || item.type === 'select-field'"
              placeholder="-"
              :options="item?.options"
              v-model="formData[formData?.type?.value][item?.key]"
              required
            />
            <FormTextarea
              v-if="item.type === 'text'"
              :label="item.text"
              :placeholder="item?.placeholder || ''"
              rows="3"
              class="big"
              :required="item?.required"
              v-model="formData[formData?.type?.value][item?.key]"
            />
            <FormInput
              v-if="item.type === 'input'"
              :label="item.text"
              onlyLatin
              placeholder="-"
              v-model="formData[formData?.type?.value][item?.key]"
            />
            <div v-if="item.type === 'checkbox'" class="grid gtc-1 ggap-20 ai-fs">
              <div class="flex ai-c">
                <h3 class="title">{{ item.text }}</h3>
              </div>
              <div v-for="field in item.options" class="grid gtc-1 ggap-20 ai-fs" :key="field.value">
                <FormCheckbox
                  class="fs-14 fw-b no-select"
                  :label="field.text"
                  :required="!Object.keys(formData[formData?.type?.value][item.key]).length"
                  :defaultValue="isSelected"
                  v-model="formData[formData?.type?.value][item.key][field?.value + (field?.withText ? 'Check' : '')]"
                />
                <FormTextarea
                  v-if="formData[formData?.type?.value][item.key][field?.value + 'Check'] && field?.withText"
                  :label="field.text"
                  :placeholder="field?.placeholder || ''"
                  rows="3"
                  class="big mh-60"
                  :required="field?.required"
                  v-model="formData[formData?.type?.value][item.key][field?.value]"
                />
              </div>
            </div>
            <div v-if="item[formData[formData?.type?.value][item?.key]]?.length" class="grid gtc-1 ggap-20 ai-fs">
              <template v-for="field in item[formData[formData?.type?.value][item?.key]]" :key="field.name">
                <FormTextarea
                  v-if="field.type === 'text'"
                  :label="field.text"
                  :placeholder="field?.placeholder || ''"
                  rows="3"
                  :required="field?.required"
                  class="big mh-60"
                  v-model="formData[formData?.type?.value][field?.key]"
                />
                <FormSelect
                  :label="field.text"
                  v-if="field?.type === 'select' || field?.type === 'select-field'"
                  placeholder="-"
                  :options="field?.options"
                  v-model="formData[formData?.type?.value][field?.key]"
                  required
                />
              </template>
            </div>
          </div>
        </section>

        <!-- Файлы -->
        <section class="form-add__section grid ggap-20">
          <div class="flex ai-c ggap-10">
            <h2 class="title">Файлы заявки</h2>
            <BaseIcon
              class="form-item__help ic-16 grey"
              icon="alert"
              v-tippy="'Прикрепите те файлы которые будут участвовать в создании или рассмотрении документа'"
            />
          </div>
          <FormFile v-model="formData.documents" />
        </section>

        <!-- Данные автора -->
        <section class="form-add__section grid ggap-20">
          <h2 class="title">Ваши данные</h2>
          <div class="flex ai-c ggap-10 fw">
            <span
              v-for="contact of contacts"
              :key="contact.id"
              class="box pt-5 pb-5 pl-10 pr-10 fs-14 flex ai-c ggap-5"
              v-tippy="'Ваш личный контакт из вашего профиля'"
            >
              <BaseIcon class="ic-18 grey" :icon="contact.type" />
              {{ contact.value }}
            </span>
            <span
              v-for="(contact, index) in formData.newContactList"
              :key="contact.value"
              class="box pt-5 pb-5 pl-10 pr-5 fs-14 flex ai-c ggap-10"
              v-tippy="'Новый контакт'"
            >
              <div class="flex ai-c ggap-5">
                <BaseIcon class="ic-18 primary" :icon="contact.type" />
                {{ contact.value }}
              </div>
              <button class="btn xsm transparent-grey rounded cube-20" @click="removeContact(index)">
                <BaseIcon class="ic-14 red" icon="close" />
              </button>
            </span>

            <!-- Новый контакт -->
            <div v-if="isAddContact" class="new-contact grid gtc-auto-1-auto ai-c ggap-5 box p-5 w-100">
              <FormSelect
                class="sm"
                label=""
                placeholder=""
                :options="contactTypes"
                v-model="newContact.type"
                required
                @keydown.enter.prevent
              />
              <FormInput
                class="sm w-100"
                label=""
                placeholder="Введите"
                v-model="newContact.value"
                @keydown.enter.prevent
                required
              />
              <div class="flex ai-c ggap-5">
                <button class="btn primary-light sm" @click="addNewContact">Сохранить</button>
                <button class="btn cancel sm" @click="isAddContact = false">Отмена</button>
              </div>
            </div>

            <!-- Добавить контакт -->
            <button
              v-else
              class="btn primary-light sm"
              @click="isAddContact = !isAddContact"
              v-tippy="
                'Можно добавить контакты свои или другого участника, эти контакты НЕ будут сохранены в ваш профиль'
              "
            >
              <BaseIcon class="ic-16 primary" icon="plus-circle" /> Добавить контакты
            </button>
          </div>
          <FormEditor
            label="Общий комментарий"
            helper="Свободный текст, пример: Свяжитесь с Васей по этим контактам +7ххх он лучше знает что нужно."
            placeholder=""
            class="big"
            v-model="formData.commentary"
          />
          <!-- @focused="setFocus('comment', $event)" -->
        </section>
      </form>
    </template>

    <!-- Foot -->
    <template #foot>
      <div class="flex ai-c jc-fe ggap-20">
        <button class="btn primary" :disabled="!isNoData" @click="submitAction">
          <BaseIcon class="ic-16 white" icon="check-circle" />
          Отправить заявку
        </button>
      </div>
    </template>
  </ModalWrapper>
</template>

<script setup>
import orders from '@/api/modules/orders'
import { ref, reactive, watch, computed, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { isInvalidForm, $busEmit, disableAllBtns } from '@/plugins'
import { forWho, contactTypes, typeOptions } from './components/Options'
import {
  FormAutocompleteSearch,
  FormAutocomplete,
  ModalWrapper,
  FormTextarea,
  FormCheckbox,
  ModalEmpty,
  FormSelect,
  FormEditor,
  FormInput,
  BaseIcon,
  FormFile,
  BaseAlert,
  BaseLoad,
  FormDate,
  ItemUser
} from '@/components'

// Data
const store = useStore()
const router = useRouter()

// Form data
const formData = reactive({
  special_conditions: '',
  release_date: '',
  commentary: '',
  newContactList: [],
  documents: [],
  company: {},
  for_user: {},
  user: {},
  type: {},
  laptop: { main_tasks: {}, software: {} },
  display: {},
  software: { software: {} },
  access: {},
  troubles: {},
  others: {},
  faceid: {}
})

// Contacts data
const isAddContact = ref(false)
const newContact = reactive({
  type: 'phone',
  value: ''
})

const leaveRoute = ref('')
const isNoSaved = ref(false)
const isLoad = ref(false)
const isUserLoad = ref(false)

// Computed
const profile = computed(() => store.getters.profile)
const optionsUsers = computed(() => store.getters.users || [])
const optionsProjects = computed(() => store.getters.projects || [])
const contacts = computed(() => store.getters?.profile?.contacts || [])
const optionsAllTypes = computed(() => store.getters.types || [])
const isNoData = computed(() => formData.type?.value && formData.company?.value)
const typeFields = computed(() => typeOptions.find((el) => el?.value === formData?.type?.value)?.fields)

// Watcher
watch(isLoad, (val) => disableAllBtns('.modal', val))

// Created
store.dispatch('GET_USERS')
store.dispatch('GET_PROJECTS')
store.dispatch('GET_TYPES')

// Router before leave
onBeforeRouteLeave((to, from, next) => {
  if (isNoData.value && !leaveRoute.value) {
    isNoSaved.value = true
    leaveRoute.value = to.fullPath
  } else {
    next()
  }
})

// Methods:users
async function searchUsers(searchText) {
  isUserLoad.value = true
  await store.dispatch('GET_USERS', searchText)
  isUserLoad.value = false
}
function removeUserItem(id) {
  const index = formData?.users?.findIndex((el) => el.id === id)
  formData?.users?.splice(index, 1)
}

// Methods
function closeAction() {
  router.replace('/orders')
}

function submitAction() {
  isNoSaved.value = false
  if (isInvalidForm('form-add')) return
  isLoad.value = true
  const formDataFinal = new FormData()
  const type = formData?.type?.value
  const type_id = formData?.type?.id

  formDataFinal.append('type_id', type_id)
  formDataFinal.append('company_id', formData?.company?.id)
  formDataFinal.append('release_date', formData?.release_date)

  if (formData?.for_user?.value === 'for_new_employee') {
    formDataFinal.append('for_new_user', formData?.new_employee)
  } else if (formData?.for_user?.value === 'for_me') {
    formDataFinal.append('for_user', profile?.value?.id)
  } else if (formData?.for_user?.value === 'for_employee') {
    formDataFinal.append('for_user', formData?.user?.id)
  }

  if (formData?.commentary) {
    const comments = formData?.commentary?.split('<p>&nbsp;</p>').join('')
    formDataFinal.append('commentary', comments)
  }

  if (formData?.documents?.length) {
    for (let i = 0; i < formData?.documents.length; i++) {
      const file = formData?.documents[i]
      formDataFinal.append('documents[]', file?.file)
    }
  }

  for (let key in formData[type]) {
    if (typeof formData[type][key] === 'object') {
      for (let k in formData[type][key]) {
        formDataFinal.append(`sub_type[${type}][${key}][${k}]`, formData[type][key][k])
      }
    } else {
      formDataFinal.append(`sub_type[${type}][${key}]`, formData[type][key])
    }
  }

  if (formData?.newContactList?.length) {
    for (let i = 0; i < formData?.newContactList?.length; i++) {
      const item = formData?.newContactList[i]
      formDataFinal.append(`contact_information[${item?.type}]`, item?.value)
    }
  }

  orders
    .create(formDataFinal)
    .then(async () => {
      await store.dispatch('orders/GET_ROWS')
      if (leaveRoute.value) {
        router.replace(leaveRoute.value)
      } else {
        leaveRoute.value = '/orders'
        await nextTick()
        router.replace('/orders')
      }

      closeAction()
      $busEmit('setToast', {
        type: 'green',
        icon: 'check-circle',
        message: 'Заявка успешно создана, ожидайте пока Сисадмины возьмут ее в работу.'
      })
    })
    .finally(() => {
      isLoad.value = false
    })
}

function removeContact(index) {
  formData.newContactList.splice(index, 1)
}

function addNewContact() {
  formData.newContactList.push({ ...newContact })
  newContact.type = 'phone'
  newContact.value = ''
  isAddContact.value = false
}
</script>

<style lang="scss" scoped>
.mh-60 {
  min-height: 60px;
}
.form-add {
  min-height: calc(100vh - 300px);
  width: 800px;
  position: relative;

  &__section {
    // border-bottom: var(--b1);
    padding: 20px;

    &:last-child {
      border: 0;
    }
    .title {
      // color: var(--primary);
    }

    &-box {
      &-btn {
        &.active {
          background-color: var(--grey);
          border-radius: var(--br) var(--br) 0 0;
        }
      }
      &-body {
        background-color: var(--box);
        border-radius: 0 0 var(--br) var(--br);
        border: var(--b1);
        border-top: 0;
        padding: 20px;
      }
    }
  }
}
</style>
